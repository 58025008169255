import landing from './landing.vue';
import prof from './profile.vue';
import cal from './calendar.vue';
import sessionForm from './sessionForm.vue';
import sessionDetail from './sessionDetail.vue';
import sessionList from './sessionList.vue';

export default[
    
    {
        path:'/landing',
        component: landing
        
    },
    {
        path:'/profile',
        component: prof
        
    },
    {
        path:'/calendar',
        component: cal,
    },
    {
        path:'/sessionForm',
        component: sessionForm,
    },
    {
        path:'/sessionList',
        component: sessionList,
    },
    {
        path:'/sessionForm/create/:date',
        component: sessionForm
    },
    {
        path:'/view/:attendanceid',
        component: sessionDetail
    },
    {
        path:'/sessionForm/edit/:attendanceid',
        component: sessionForm
    }

]