<template lang="pug">
    .position-relative
        PWAPrompt(:permanently-hide-on-dismiss="false")
        b-alert.position-absolute.mb-0(:show="installReady" style="width:100%; z-index:2; bottom:0;" variant="dark")
            .position-relative
                .position-absolute.bg-white.rounded-circle.d-flex.align-items-center.justify-content-center(@click="dismiss" style="z-index:2; right:-15px; top:-18px;width:20px;height:20px; opacity:.6; cursor:pointer;" fade)
                    b-icon.m-0(icon="x-circle" font-scale="0.8" )
                b-button.py-1(@click="installApp" size="sm" block) 
                    b-icon.mr-2(icon="download")
                    span Install App
        b-overlay(:show="!ready")
            router-view#app(v-if="ready")

</template>
<script>
import PWAPrompt from 'vue2-ios-pwa-prompt'

export default {
    components:{
        PWAPrompt
    },
    data(){
        return {
            installClicked:false
        }
    },
    computed:{
        ready(){
            return this.$root.ready;
        },
        installReady(){
            if(this.installClicked){
                return false
            }
            //return true;
            return !!this.$root.installEvent;
        },
        installEvent(){
            return this.$root.installEvent;
        }
    },
    methods:{
        dismiss(){
            this.installClicked = true;

        },
        installApp(){
            this.installClicked = true;
            this.installEvent.prompt();
            this.installEvent.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === "accepted") {
                    console.log("User accepted the A2HS prompt");
                } else {
                    console.log("User dismissed the A2HS prompt");
                }
            });
        }
    }
}
</script>