<template lang="pug">
b-overlay(:show="!ready")
        b-container.container-lg
            //- b-button(@click="consoleLogTest") Test
            b-card#sesh-form

                b-modal#choiceWarningBox(title="Warning" centered header-bg-variant="danger" header-text-variant="white" no-close-on-backdrop no-close-on-esc hide-header-close)
                    h4  It seems like your account is not assigned to any main or additional role, please contact LCSS staff for support
                    template(#modal-footer)
                        b-button(variant="primary" size="md" class="float-right" @click="$router.go(-1)") OK

                b-modal#warningBox(title="Warning" centered header-bg-variant="danger" header-text-variant="white")
                    h4  If you proceed to go back, all changes made will not be saved. Are you sure?
                    template(#modal-footer)
                        b-button(variant="danger" size="md" class="float-right" @click="$bvModal.hide('warningBox')") No
                        b-button(variant="primary" size="md" class="float-right" @click="$router.go(-1)") Yes

                b-form(@submit.prevent="submitForm" v-if="!this.$route.params?.attendanceid")
                    h3.title2.my-4(ref="top") Volunteer Sessions
                    div.d-flex.justify-content-between.my-3
                        b-button(@click="$bvModal.show('warningBox')" active-class="active" tag="button" exact)
                            div.link-container &larr; Back
                        
                    div(v-if="submitClicked")
                        p.lead.text-dark.text-center {{sending ? 'Sending Data...' : 'Submission Complete!' }}
                        div.text-center
                            lottie-vue-player(
                                :src="currentLottie"
                                :autoplay="true"
                                :loop="true"
                                :speed="1"
                                ref="lottie")

                    div#formdiv.p-1.mt-4(v-else ref="maincard" overlay)

                        //Modal if User did not fill every input
                        b-modal#errorBox(title="Error" centered header-bg-variant="danger" header-text-variant="white")
                            h4 Please Enter Every Field
                            template(#modal-footer)
                                b-button(variant="danger" size="md" class="float-right" @click="$bvModal.hide('errorBox')") Close

                        b-form-group
                            label Volunteer Role:
                            b-select(v-model="formdata.crf69_membertype" :options="memberchoice"  required)
                        hr
                        p.s-size(v-if="formdata.crf69_membertype === 955870000") Thank you for being a volunteer with LCSS :&#41; This form will help us better understand the needs of the child / family and address urgent issues. It will also allow us to record volunteer hours - a requirement by our regulatory body, the National Council of Social Service &#40;NCSS&#41;. Please update your mentoring summary notes after every session. Thank you so much for your time! 
                            ul Note:
                                li For matters relating to mentoring - reach out to the Life! Mentors team - 9119 9854 &#40;WhatsApp&#41;.
                                li For matters relating to the child/family and/or urgent matters - reach out to the caseworker/programme staff in-charge directly 

                                p.s-size We wish you a fruitful volunteering journey with us!
                        p.s-size(v-if="formdata.crf69_membertype === 955870001") Thank you for being a volunteer with LCSS :&#41; This form will help us better understand the needs of the child / family and address urgent issues. It will also allow us to record volunteer hours - a requirement by our regulatory body, the National Council of Social Service &#40;NCSS&#41;. Please update your mentoring summary notes after every session. Thank you so much for your time! We wish you a fruitful volunteering journey with us!
                        p.s-size(v-if="formdata.crf69_membertype === 955870002") Thank you for being a volunteer with LCSS :&#41; This form will help us better understand the needs of the child / family and address urgent issues. It will also allow us to record volunteer hours - a requirement by our regulatory body, the National Council of Social Service &#40;NCSS&#41;. Thank you so much for your time! We wish you a fruitful volunteering journey with us!
                        b-form-group
                            label Activity Type: 
                            .d-flex.justify-content-around
                                b-form-radio(v-model="selected" name="activity-radios" @change="isRecurringRadio" value="a" required ) Recurring Activities
                                b-form-radio(v-model="selected" name="activity-radios" @change="isNormalRadio" value="b" required  ) Activities
                        b-form-group(v-if="selected")
                            label Activity Name:     
                            b-select(v-if="selected==='a'" @change="selectRecurringActivity" :options="recurringActivityListParsed" v-model="recurring_activity_id" ref="rec_id" required)
                            b-select(v-if="selected==='b'" @change="selectNormalActivity" :options="normalActivityListParsed" v-model="normal_activity_id" ref="rec_id" required)
                        hr
                        b-form-group(v-if="formdata.crf69_membertype === 955870000 || formdata.crf69_membertype === 955870001" )
                            label Mentee:
                            b-select(:options="beneList" text-field="crf69_beneid" value-field="odata_obj"  v-model="formdata[`crf69_Beneficiary@odata.bind`]" required)
                        b-form-group(style="position:relative")
                            label Session Date:
                            b-form-datepicker(ref="date" @input="fixDate" v-model="raw_date" required)
                            b-input(v-model="raw_date" style="opacity:0; z-index:0; position:absolute" required)
                        hr
                        b-form-group(style="position:relative")
                            label Session Time:
                            input#timeinput(ref="time" type="time" @input="fixDate" v-model="raw_time" style="width:100%; text-align:center;" required)
                            b-input(v-model="raw_time" style="opacity:0; z-index:0; position:absolute" required)
                        hr
                        b-form-group(style="position:relative")
                            label Session Duration (Hours):
                            vue-numeric-input(controlsType="plusminus" width="100%" :min="0" :step="0.5" :precision="2" v-model="raw_duration" @input="setEndDate" required)
                            b-input(v-model="raw_duration" style="opacity:0; z-index:0; position:absolute" required)

                        b-form-group(v-if="formdata.crf69_membertype === 955870000 || formdata.crf69_membertype === 955870001")
                            label Meeting Place:
                            p.s-size If meeting was cancelled, please choose &#40;Others&#41;, and type in the reason. Gentle reminder that mentoring sessions should not be held in Mentee's house unless said otherwise. Mentors are strongly advised not to have mentoring sessions at crowded places.

                            b-select.spacing(v-model="formdata.crf69_meetingplace" :options="picklists.crf69_meetingplace" required)
                            b-textarea(v-if="formdata.crf69_meetingplace===955870003" v-model="formdata.crf69_meetingplacenotes" rows="3" required)

                        b-form-group(v-if="formdata.crf69_membertype === 955870001")
                            label Session Notes (please add comments on what was covered, progress, and any concerns): 
                            b-form-textarea(v-model="formdata.crf69_remarks" rows="4" required)
                        b-form-group(v-if="formdata.crf69_membertype ===  955870002" )
                            label Comments on the session (e.g. what went well, any areas of concern): 
                            b-form-textarea(v-model="formdata.crf69_remarks" rows="4" required)
                        
                        
                        b-form-group(v-if="formdata.crf69_membertype === 955870000" )
                            label Topics/Issues Discussed: 
                            p.s-size Mentoring sessions are specially carved out time for you to get to know your mentee better, be a trusted adult friend and role model, a cheerleader and encourager, and to reinforce positive learning in your mentee's life. For other topics discussed, select &#40;Others&#41;. Do share more with us :&#41;
                            multiselect.spacing(placeholder="Please select at least 1 option" v-model="formdata.crf69_topicsissuesdiscussed" :options="picklists.crf69_topicsdiscussed" label="text" :close-on-select="false" :multiple="true" :searchable="false" track-by="value")
                            b-textarea(v-if="formdata.crf69_topicsissuesdiscussed.find(opt => opt.id === 955870015)" v-model="formdata.crf69_topicnotes" rows="3" required)
                            br    
                        b-form-group(v-if="formdata.crf69_membertype === 955870000")
                            label How was the mentee's temperament/attitude during the mentoring session? 
                            multiselect.spacing(placeholder="Please select at least 1 option" v-model="formdata.crf69_tempermentofmentee" :options="picklists.crf69_menteetempermant" label="text" :close-on-select="false" :multiple="true" :searchable="false" track-by="value")
                            b-textarea(v-if="formdata.crf69_tempermentofmentee.find(opt => opt.id === 955870011)" v-model="formdata.crf69_temperamentnotes" rows="3" required)
                            br
                        b-form-group(v-if="formdata.crf69_membertype === 955870000")
                            label How is the mentee's family situation? 
                            multiselect.spacing(placeholder="Please select at least 1 option" v-model="formdata.crf69_menteefamilysituation" :options="picklists.crf69_familyrelationship" label="text" :close-on-select="false" :multiple="true" :searchable="false" track-by="value")
                            b-textarea(v-if="formdata.crf69_menteefamilysituation.find(opt => opt.id === 955870009)" v-model="formdata.crf69_familynotes" rows="3" required)
                            br
                        b-form-group(v-if="formdata.crf69_membertype === 955870000" )
                            label Comments / Areas of concern
                            p.s-size Please state activities during mentoring session and any additional concerns you have regarding the mentee / family.
                            b-textarea(v-model="formdata.crf69_areasofconcern" rows="3" required)
                            br
                        b-form-group(v-if="formdata.crf69_membertype === 955870000")
                            label Anything you want to say to the case workers? 
                            b-textarea(v-model="formdata.crf69_comments_caseworker" rows="3" required)
                            br
                            br
                        b-form-group(v-if="formdata.crf69_membertype === 955870000" )
                            label What were some challenges you faced? And how can we better support you? 
                            b-textarea(v-model="formdata.crf69_challengesfaced" rows="3" required)
                        b-form-group(v-if="formdata.crf69_membertype === 955870000" )
                            label Any other feedback for us? 😊
                            p.s-size Please indicate "Nil" if you have no comments on the above.
                            b-textarea(v-model="formdata.crf69_remarks" rows="3" required)
                        
                        b-form-group
                            label By submitting this form, you have declared that:
                            p.s-size You have provided accurate information in this declaration.
                            p.s-size You understand that this declaration is to safeguard the beneficiaries, their families, and other volunteers that you work with. 
                        hr
                        div.d-flex
                            b-button.mx-auto(type="submit") Submit

                //- Edit Form
                b-form(@submit.prevent="editForm" v-if="this.$route.params?.attendanceid")
                    h3.title2.my-4(ref="top") Edit Sessions
                    div.d-flex.justify-content-between.my-3
                        b-button(@click="$bvModal.show('warningBox')" active-class="active" tag="button" exact)
                            div.link-container &larr; Back
                        //- b-button(type="submit" @click="editSendingNow") Submit
                    div(v-if="submitClicked")
                        p.lead.text-dark.text-center {{sending ? 'Sending Data...' : 'Edit Complete!' }}
                        div.text-center
                            lottie-vue-player(
                                :src="currentLottie"
                                :autoplay="true"
                                :loop="true"
                                :speed="1"
                                ref="lottie")

                    div#formdiv.p-1(v-else ref="maincard" overlay)

                        b-form-group(label="Volunteer Role:")
                            b-select(v-model="formdata.crf69_membertype" :options="picklists.crf69_membertype2" )
                        hr
                        b-form-group(label="Activity Type: ")
                            .d-flex.justify-content-around
                                b-form-radio(v-model="selected" name="activity-radios" @change="isRecurringRadio" value="a" ) Recurring Activities
                                b-form-radio(v-model="selected" name="activity-radios" @change="isNormalRadio" value="b" ) Activities
                        b-form-group(v-if="selected" label="Activity Name")    
                            b-select(v-if="selected==='a'" :options="recurringActivityListParsed" v-model="recurring_activity_id" ref="rec_id" )
                            b-select(v-if="selected==='b'" :options="normalActivityListParsed" v-model="normal_activity_id" ref="rec_id" )
                        hr
                        b-form-group(v-if="editAttendance.crf69_membertype === 955870000 || editAttendance.crf69_membertype === 955870001 || formdata.crf69_membertype === 955870000 || formdata.crf69_membertype === 955870001" label="Mentee: ")
                            b-select(:options="beneList" text-field="crf69_beneid" value-field="odata_obj"  v-model="formdata[`crf69_Beneficiary@odata.bind`]")

                        b-form-group(label="Session Date:" style="position:relative")
                            b-form-datepicker(ref="date" @input="fixDate" v-model="raw_date" )
                            b-input(v-model="raw_date" style="opacity:0; z-index:0; position:absolute" )

                        b-form-group(label="Session Time: " style="position:relative")
                            input#timeinput(ref="time" type="time" @input="fixDate" v-model="raw_time" style="width:100%; text-align:center;" )
                            b-input(v-model="raw_time" style="opacity:0; z-index:0; position:absolute" )
                        hr
                        b-form-group(label="Session Duration (Hours): " style="position:relative")
                            vue-numeric-input(controlsType="plusminus" width="100%" :min="0" :step="0.5" :precision="2" v-model="raw_duration" @input="setEndDate")
                            b-input(v-model="raw_duration" style="opacity:0; z-index:0; position:absolute" )
                        hr       
                        b-form-group(v-if="formdata.crf69_membertype === 955870000 || formdata.crf69_membertype === 955870001" label="Meeting Place: ")
                            p.s-size If meeting was cancelled, please choose &#40;Others&#41;, and type in the reason. Gentle reminder that mentoring sessions should not be held in Mentee's house unless said otherwise. Mentors are strongly advised not to have mentoring sessions at crowded places.
                            b-select.spacing(v-model="formdata.crf69_meetingplace" :options="picklists.crf69_meetingplace" )
                            b-textarea(v-if="formdata.crf69_meetingplace===955870003" v-model="formdata.crf69_meetingplacenotes" rows="3" )
                        hr

                        b-form-group(v-if="editAttendance.crf69_membertype === 955870001 || formdata.crf69_membertype === 955870001" label="Session Notes (please add comments on what was covered, progress, and any concerns):")
                            b-form-textarea(v-model="formdata.crf69_remarks" rows="4" :placeholder="editAttendance.crf69_remarks")

                        b-form-group(v-if="formdata.crf69_membertype === 955870002 || formdata.crf69_membertype === 955870002" label="Comments on the session (e.g. what went well, any areas of concern):")
                            b-form-textarea(v-model="formdata.crf69_remarks" rows="4" :placeholder="editAttendance.crf69_remarks")

                        b-form-group(v-if="formdata.crf69_membertype === 955870000" label="Topics/Issues Discussed: ")
                            p.s-size Mentoring sessions are specially carved out time for you to get to know your mentee better, be a trusted adult friend and role model, a cheerleader and encourager, and to reinforce positive learning in your mentee's life. For other topics discussed, select &#40;Others&#41;. Do share more with us :&#41;
                            multiselect.spacing(:placeholder="editAttendance[`crf69_topicsissuesdiscussed@OData.Community.Display.V1.FormattedValue`]" v-model="formdata.crf69_topicsissuesdiscussed"  :options="picklists.crf69_topicsdiscussed" label="text" :close-on-select="false" :multiple="true" :searchable="false" track-by="value")
                            b-textarea(:placeholder="editAttendance.crf69_topicnotes" v-model="formdata.crf69_topicnotes" rows="3" )
                        br    
                        b-form-group(v-if="formdata.crf69_membertype === 955870000" label="How was the mentee's temperament/attitude during the mentoring session?")
                            multiselect.spacing(:placeholder="editAttendance[`crf69_tempermentofmentee@OData.Community.Display.V1.FormattedValue`]" v-model="formdata.crf69_tempermentofmentee" :options="picklists.crf69_menteetempermant" label="text" :close-on-select="false" :multiple="true" :searchable="false" track-by="value")
                            b-textarea(:placeholder="editAttendance.crf69_temperamentnotes" v-model="formdata.crf69_temperamentnotes" rows="3" )
                        br
                        b-form-group(v-if="formdata.crf69_membertype === 955870000" label="How is the mentee's family situation?")
                            multiselect.spacing(:placeholder="editAttendance[`crf69_menteefamilysituation@OData.Community.Display.V1.FormattedValue`]" v-model="formdata.crf69_menteefamilysituation" :options="picklists.crf69_familyrelationship" label="text" :close-on-select="false" :multiple="true" :searchable="false" track-by="value")
                            b-textarea(:placeholder="editAttendance.crf69_familynotes" v-model="formdata.crf69_familynotes" rows="3" )
                        br
                        b-form-group(v-if="formdata.crf69_membertype === 955870000" label="Comments / Areas of concern")
                            p.s-size Please state activities during mentoring session and any additional concerns you have regarding the mentee / family.
                            b-textarea(v-model="formdata.crf69_areasofconcern" :placeholder="editAttendance.crf69_areasofconcern" rows="3" )
                        br
                        b-form-group(v-if="formdata.crf69_membertype === 955870000" label="Anything you want to say to the case workers?")
                            b-textarea(v-model="formdata.crf69_comments_caseworker" :placeholder="editAttendance.crf69_comments_caseworker" rows="3" )
                        br
                        br
                        b-form-group(v-if="formdata.crf69_membertype === 955870000" label="What were some challenges you faced? And how can we better support you?")
                            b-textarea(v-model="formdata.crf69_challengesfaced" rows="3" :placeholder="editAttendance.crf69_challengesfaced" )

                        b-form-group(v-if="formdata.crf69_membertype === 955870000" label="Any other feedback for us? 😊")
                            p.s-size Please indicate "Nil" if you have no comments on the above.
                            b-textarea(v-model="formdata.crf69_remarks" rows="3" :placeholder="editAttendance.crf69_remarks" )
                        
                        b-form-group(label="By submitting this form, you have declared that:")
                            p.s-size You have provided accurate information in this declaration.
                            p.s-size You understand that this declaration is to safeguard the beneficiaries, their families, and other volunteers that you work with. 
                        hr
                        div.d-flex
                            b-button.mx-auto(@click="editForm") Submit
</template>
<script>
import formmixin from './formmixin.js';
import dayjs from "dayjs";
import Multiselect from 'vue-multiselect';
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("Asia/Singapore")
export default {
    mixins: [formmixin],
    name: "sessionForm",
    components: {
        Multiselect
    },
    data() {
        return {
            memberchoice:[],
            bothNormalActList:[],
            volInfo:[],
            selected:"",
            editAttendance:[],
            editCheckActivityType:"",
            editBeneficiary:"",
            currentLottie: "/lottie/sending.json",
            sending: false,
            submitClicked: false,
            ready:false,
            submitting:false,
            normal_activities:[],
            activityList:[],
            beneList:[],
            normal_activity_id:"",
            recurring_activity_id:"",
            raw_date:"",
            raw_time:"",
            raw_duration:"",
            formdata:{
                crf69_membertype:"", //Attendance
                crf69_meetingplace:"",//Attendance
                crf69_topicsissuesdiscussed:[],//Attendance
                crf69_tempermentofmentee:[],//Attendance
                crf69_menteefamilysituation:[],//Attendance
                crf69_datetime: '',//Attendance
                "crf69_Beneficiary@odata.bind":"",//attendance
                crf69_challengesfaced:"",
                crf69_attendanceend:"",
                crf69_familynotes:"",
                crf69_temperamentnotes:"",
                crf69_topicnotes:"",
                crf69_remarks:"",
                crf69_areasofconcern:"",
                crf69_meetingplacenotes:"",
                crf69_comments_caseworker:"",
            },
            picklists: {
                crf69_membertype2:[],
                crf69_meetingplace:[],
                crf69_topicsdiscussed:[],
                crf69_menteetempermant:[],
                crf69_familyrelationship:[],
                crf69_attendance:[],
            },
            non_org_act_list:[],
        };
    },
    async mounted(){
        let $ = this.$root.create_client();
        const attendance_id = this.$route.params.attendanceid;
        let arr = [
            $.get("/api/session").then(({data})=>{//bad naming convention probably.
                this.activityList = data.activity; // recur act
                this.normal_activities = data.orgactivity; // org normal act
                this.non_org_act_list= data.nonorgnormalactivity; // non org normal act
                this.volInfo = data.voldata[0]; //member role
                this.beneList = data.mentee.map(f=>{
                    f.odata_obj = `/crf69_beneficiaries(${f.crf69_beneficiaryid})`
                    return f;
                });
                return data;
            }), 
            $.get("/api/picklists/").then(({data})=>{
                this.picklists= data;
                return data;
            }),
        ];

        console.log(arr)

        if (attendance_id) {
            arr.push($.get(`/api/past_sessions?attendance_id=${attendance_id}`).then(({data})=>{//bad naming convention probably.
                this.editAttendance = data[0];
                if(!this.editAttendance.crf69_Activities){
                    this.editCheckActivityType=null
                } else{
                    this.editCheckActivityType=data[0].crf69_Activities.crf69_activitiesid //get the activity id for checking activity type of edited session
                }
                if(!this.editAttendance.crf69_Beneficiary){
                    this.editBeneficiary=null
                } else{
                    this.editBeneficiary=data[0].crf69_Beneficiary.crf69_beneficiaryid //get the activity type for edited session
                }
                return data;
            }))
        }
        Promise.all(arr).then((r)=>{
            console.log('checking', r)
            this.changeMultiselectLabel()
            this.ready = true;
            this.filterMemberChoice()
            this.combineBothNormalAct()
            this.fillInEditInput()
        }).catch(()=>{
            alert("Unable to connect to the system. Please try your submission another time.");
        })

        // if (this.$route.params?.date) {
        //     this.raw_date = this.$route.params?.date;
        // }
    },

    methods:{
        combineBothNormalAct(){
            let arr = [...this.normal_activities, ...this.non_org_act_list];
            this.bothNormalActList = [...new Set(arr)] //to be used as choices for 'activities'
            this.bothNormalActList = arr.filter(function({crf69_activitiesid}) {
                return !this.has(crf69_activitiesid) && this.add(crf69_activitiesid);
            }, new Set)
            console.log(this.bothNormalActList)
        },
        consoleLogTest(){
            console.log(this.normalActivityListParsed)
            this.filterMemberChoice()
        },
        filterMemberChoice(){
            let mainRole
            let additionalRole
            if(!this.volInfo.crf69_role && !this.volInfo.crf69_additionalroles){
                this.$bvModal.show('choiceWarningBox')
            }
            if(!this.volInfo.crf69_role){
                mainRole = []
            }else{
                mainRole = [this.volInfo.crf69_role.toString()]
            }
            if(!this.volInfo.crf69_additionalroles){
                additionalRole = []
            }else{
                additionalRole = this.volInfo.crf69_additionalroles.split(',')
            }
            let allRoles = mainRole.concat(additionalRole)
            const LIFEMEMBER = this.picklists.crf69_membertype2[0]
            const OTHERMENTOR = this.picklists.crf69_membertype2[1]
            const OTHERVOL = this.picklists.crf69_membertype2[2]
            const CORPVOL = this.picklists.crf69_membertype2[3]
            console.log(allRoles)
            if (allRoles.includes('955870002')){
                this.memberchoice.push(LIFEMEMBER)
            }
            if (allRoles.includes('955870003')||allRoles.includes('955870005')){
                this.memberchoice.push(OTHERMENTOR)
            }
            if (allRoles.includes('955870001')||allRoles.includes('955870004')||allRoles.includes('955870000')||allRoles.includes('955870008')){
                this.memberchoice.push(OTHERVOL)
            }
            if (allRoles.includes('955870006')||allRoles.includes('955870007')){
                this.memberchoice.push(CORPVOL)
            }
            console.log("member choice:", this.memberchoice)
        },
        fillInEditInput(){
            const attendance_id = this.$route.params.attendanceid;
            if(attendance_id){
                this.formdata.crf69_membertype=this.editAttendance.crf69_membertype
                this.formdata.crf69_meetingplace=this.editAttendance.crf69_meetingplace
                this.raw_date = dayjs(this.editAttendance.crf69_datetime).format('YYYY-MM-DD')
                this.raw_time = dayjs(this.editAttendance.crf69_datetime).format("HH:mm")
                this.fixDate()
                this.formdata.crf69_challengesfaced=this.editAttendance.crf69_challengesfaced,
                this.formdata.crf69_familynotes=this.editAttendance.crf69_familynotes,
                this.formdata.crf69_temperamentnotes=this.editAttendance.crf69_temperamentnotes,
                this.formdata.crf69_topicnotes=this.editAttendance.crf69_topicnotes,
                this.formdata.crf69_remarks=this.editAttendance.crf69_remarks,
                this.formdata.crf69_areasofconcern=this.editAttendance.crf69_areasofconcern,
                this.formdata.crf69_meetingplacenotes=this.editAttendance.crf69_meetingplacenotes,
                this.formdata.crf69_comments_caseworker=this.editAttendance.crf69_comments_caseworker,
                this.raw_duration = dayjs(this.editAttendance.crf69_attendanceend).format("HH") - dayjs(this.editAttendance.crf69_datetime).format("HH")
                //for auto populate type of act
                if(!this.editCheckActivityType){
                    console.log('activity type == null')
                    this.selected = null
                }else if(!this.bothNormalActList.map(item => item.crf69_activitiesid).includes(this.editCheckActivityType)){
                    console.log('activity type == recur')
                    this.selected = 'a'
                    this.recurring_activity_id=this.editAttendance.crf69_Activities._crf69_parentrecurringactivity_value
                }else if(this.bothNormalActList.map(item => item.crf69_activitiesid).includes(this.editCheckActivityType)){
                    console.log('activity type == normal')
                    this.selected = 'b'
                    this.normal_activity_id=this.editCheckActivityType
                }
                //for auto populate beneficiary
                if(!this.editBeneficiary){
                    return
                }else{
                    this.formdata[`crf69_Beneficiary@odata.bind`] = `/crf69_beneficiaries(${this.editBeneficiary})`
                }
            }
        },
        changeMultiselectLabel(){
            let testmultiselect = document.getElementsByClassName("multiselect__option")
            for (let i = 0; i < testmultiselect.length; i++) {
                testmultiselect[i].dataset.select = "Tap to select"
                testmultiselect[i].dataset.deselect = "Tap again to remove"
            }
        },
        lottieRedirect(){
            this.currentLottie="/lottie/done.json";
            let lottie = this.$refs.lottie.$el.querySelector('lottie-player');
            lottie.load(this.currentLottie);
            this.sending = false;
            setTimeout(() => this.$root.goto("calendar"), 2000);
        },
        errorRedirect(){
            alert("Failed to submit.")
            this.$root.goto("calendar")
        },
        selectRecurringActivity(){
            let filteredRecurringActivity = this.activityList.filter((activity)=>{
                return activity.crf69_recurringactivityid == this.recurring_activity_id;
            })
            console.log(filteredRecurringActivity)
            // let sg_time = (dayjs.tz(filteredRecurringActivity[0].crf69_scheduledtime,"Asia/Singapore").$d)
            // console.log(sg_time)
            this.raw_time = filteredRecurringActivity[0].crf69_scheduledtime
            this.raw_duration = (filteredRecurringActivity[0].crf69_duration/60)
            this.fixDate()
            this.setEndDate()
        },
        selectNormalActivity(){
            let filteredNormalActivity = this.bothNormalActList.filter((activity)=>{
                return activity.crf69_activitiesid == this.normal_activity_id;
            })
            console.log(filteredNormalActivity)
            let sg_time = (dayjs.tz(filteredNormalActivity[0].crf69_scheduledon,"Asia/Singapore").$d)
            console.log(sg_time)
            this.raw_time = dayjs(sg_time).format('HH:mm')
            this.raw_date = dayjs(sg_time).format('YYYY-MM-DD') 
            this.raw_duration = (filteredNormalActivity[0].crf69_duration/60)
            this.fixDate()
            this.setEndDate()
        },
        isRecurringRadio(){
            this.normal_activity_id = ""
        },
        isNormalRadio(){
            this.recurring_activity_id = ""
        },
        sendingNow(){ //For Submit
            if(this.formdata.crf69_membertype=== 955870000){
                if(!this.formdata.crf69_topicsissuesdiscussed.length || !this.formdata.crf69_tempermentofmentee.length || !this.formdata.crf69_menteefamilysituation.length){      
                    this.$bvModal.show("errorBox")
                } else {
                    this.submitClicked= true;
                    this.sending = true;
                    this.currentLottie = "/lottie/sending.json";
                }
            } else{
                this.submitClicked= true;
                this.sending = true;
                this.currentLottie = "/lottie/sending.json";
            }
        },
        editSendingNow(){ // For Edit
            this.submitClicked= true;
            this.sending = true;
            this.currentLottie = "/lottie/sending.json";
        },
        // autocompleteSafariFix() {
        //     this.changeMultiselectLabel();
        //     if (navigator?.userAgent?.indexOf("Safari") != -1) {
        //         this.recurring_activity_id = this.$refs['rec_id'].$el.value;
        //     }
        // },
        setEndDate(){
            this.formdata.crf69_attendanceend = dayjs(this.formdata.crf69_datetime).add(this.raw_duration, 'hours').toISOString();
        },
        fixDate() {
            if (!this.raw_time) {
                this.formdata.crf69_datetime = dayjs(this.raw_date, 'YYYY-MM-DD').toISOString();
                return;
            }
            this.formdata.crf69_datetime = dayjs(`${this.raw_date} ${this.raw_time}`, 'YYYY-MM-DD HH:mm:ss').toISOString();
        },
        //On submission - send a request to the backend indicating the recurring activity id (via post api_session)
        async submitForm(){
            this.fixDate()
            this.setEndDate()
            if(this.selected == "a"){
                let recurring_activity_id = this.recurring_activity_id;
                let attendance_obj = this.finalData;
                let $ = this.$root.create_client(); // by right I shd be using vueX, but this is faster and we dont have much time :/
                this.sendingNow();
                console.log("Submit Recurring Activity Attendance")
                if(this.sending == true){
                    try{
                        await $.post("/api/session", {recurring_activity_id,attendance_obj});
                        this.lottieRedirect()
                    }catch{
                        this.errorRedirect()
                    }
                }
            } else if (this.selected == "b"){
                let normal_activity_id = this.normal_activity_id;
                let attendance_obj = this.finalData;
                let $ = this.$root.create_client();
                this.sendingNow();
                console.log("Submit Normal Activity Attendance")
                if(this.sending == true){
                    try{
                        await $.post("/api/activity", {normal_activity_id,attendance_obj});
                        this.lottieRedirect()
                    }catch{
                        this.errorRedirect()
                    }
                }
            }
        },

        async editForm(){
            this.fixDate()
            this.setEndDate()
            let attendance_id = this.$route.params.attendanceid;
            let attendance_obj = this.finalData;
            let $ = this.$root.create_client();// by right I shd be using vueX, but this is faster and we dont have much time :/
            this.editSendingNow()
            if(this.recurring_activity_id){
                if(!this.editCheckActivityType){
                    console.log('null to recur')
                    let singleAct = this.recurringActivityListParsed.find((act) => act.value == this.recurring_activity_id); //get single object of selected activity {text & value}
                    let recurring_activity_obj = {
                        crf69_activityid: `${singleAct.text} (${dayjs(this.editAttendance.crf69_datetime).format('YYYY-MM-DD')})`,//take the "text" from the selected single activity object
                        crf69_scheduledon: dayjs.utc(this.formdata.crf69_datetime).utcOffset(0,true),
                        crf69_duration: this.raw_duration * 60,
                        "crf69_ParentRecurringActivity@odata.bind": `/crf69_recurringactivities(${singleAct.value})`,
                    }
                    if(singleAct.scheme){
                        recurring_activity_obj[ "crf69_Schemes@odata.bind" ] = `/crf69_schemeses(${singleAct.scheme})`
                    }else if(!singleAct.scheme){
                        recurring_activity_obj[ "crf69_Schemes@odata.bind" ] = null
                    }
                    if(singleAct.pro){
                        recurring_activity_obj[ "crf69_Programmes@odata.bind" ] = `/crf69_programmes(${singleAct.pro})`
                    }else if(!singleAct.pro){
                        recurring_activity_obj[ "crf69_Programmes@odata.bind" ] = null
                    }
                    if(singleAct.org){
                        recurring_activity_obj[ "crf69_OrganisationAssociation@odata.bind" ] = `/crf69_externalorganizations(${singleAct.org})`
                    }else if(!singleAct.org){
                        recurring_activity_obj[ "crf69_OrganisationAssociation@odata.bind" ] = null
                    }
                    try{
                        await $.patch("/api/session", {attendance_id,attendance_obj});
                        let {data} = await $.patch("/api/session", recurring_activity_obj); //post
                        let activityId = data
                        let normal_recur_activity_obj = {
                            "crf69_Activities@odata.bind": `/crf69_activitieses(${activityId})`
                        }
                        await $.patch("/api/session", {attendance_id,normal_recur_activity_obj}); //patch
                        this.lottieRedirect()
                    }catch{
                        this.errorRedirect()
                    }
                }else if(!this.bothNormalActList.map(item => item.crf69_activitiesid).includes(this.editCheckActivityType)){
                    console.log('recur to recur')
                    let singleAct = this.recurringActivityListParsed.find((act) => act.value == this.recurring_activity_id); //get single object of selected activity {text & value}
                    let recur_recur_act_id = this.editAttendance.crf69_Activities.crf69_activitiesid //get the id of activity you want to edit
                    let recur_recur_activity_obj = {
                        crf69_activityid: `${singleAct.text} (${dayjs(this.editAttendance.crf69_datetime).format('YYYY-MM-DD')})`,//take the "text" from the selected single activity object
                        crf69_scheduledon: dayjs.utc(this.formdata.crf69_datetime).utcOffset(0,true),
                        crf69_duration: this.raw_duration * 60,
                        "crf69_ParentRecurringActivity@odata.bind": `/crf69_recurringactivities(${singleAct.value})`,
                    }
                    if(singleAct.scheme){
                        recur_recur_activity_obj[ "crf69_Schemes@odata.bind" ] = `/crf69_schemeses(${singleAct.scheme})`
                    }else if(!singleAct.scheme){
                        recur_recur_activity_obj[ "crf69_Schemes@odata.bind" ] = null
                    }
                    if(singleAct.pro){
                        recur_recur_activity_obj[ "crf69_Programmes@odata.bind" ] = `/crf69_programmes(${singleAct.pro})`
                    }else if(!singleAct.pro){
                        recur_recur_activity_obj[ "crf69_Programmes@odata.bind" ] = null
                    }
                    if(singleAct.org){
                        recur_recur_activity_obj[ "crf69_OrganisationAssociation@odata.bind" ] = `/crf69_externalorganizations(${singleAct.org})`
                    }else if(!singleAct.org){
                        recur_recur_activity_obj[ "crf69_OrganisationAssociation@odata.bind" ] = null
                    }
                    try{
                        await $.patch("/api/session", {attendance_id,attendance_obj});
                        await $.patch("/api/session", {recur_recur_act_id,recur_recur_activity_obj});
                        this.lottieRedirect()
                    }catch{
                        this.errorRedirect()
                    }
                }else if(this.bothNormalActList.map(item => item.crf69_activitiesid).includes(this.editCheckActivityType)){
                    console.log('normal to recur')
                    let singleAct = this.recurringActivityListParsed.find((act) => act.value == this.recurring_activity_id); //get single object of selected activity {text & value}
                    console.log(singleAct)
                    let recurring_activity_obj = {
                        crf69_activityid: `${singleAct.text} (${dayjs(this.editAttendance.crf69_datetime).format('YYYY-MM-DD')})`,//take the "text" from the selected single activity object
                        crf69_scheduledon: dayjs.utc(this.formdata.crf69_datetime).utcOffset(0,true),
                        crf69_duration: this.raw_duration * 60,
                        "crf69_ParentRecurringActivity@odata.bind": `/crf69_recurringactivities(${singleAct.value})`,
                    }
                    if(singleAct.scheme){
                        recurring_activity_obj[ "crf69_Schemes@odata.bind" ] = `/crf69_schemeses(${singleAct.scheme})`
                    }else if(!singleAct.scheme){
                        recurring_activity_obj[ "crf69_Schemes@odata.bind" ] = null
                    }
                    if(singleAct.pro){
                        recurring_activity_obj[ "crf69_Programmes@odata.bind" ] = `/crf69_programmes(${singleAct.pro})`
                    }else if(!singleAct.pro){
                        recurring_activity_obj[ "crf69_Programmes@odata.bind" ] = null
                    }
                    if(singleAct.org){
                        recurring_activity_obj[ "crf69_OrganisationAssociation@odata.bind" ] = `/crf69_externalorganizations(${singleAct.org})`
                    }else if(!singleAct.org){
                        recurring_activity_obj[ "crf69_OrganisationAssociation@odata.bind" ] = null
                    }
                    try{
                        await $.patch("/api/session", {attendance_id,attendance_obj});
                        let {data} = await $.patch("/api/session", recurring_activity_obj); //post
                        let activityId = data
                        let normal_recur_activity_obj = {
                            "crf69_Activities@odata.bind": `/crf69_activitieses(${activityId})`
                        }
                        await $.patch("/api/session", {attendance_id,normal_recur_activity_obj}); //patch
                        this.lottieRedirect()
                    }catch{
                        this.errorRedirect()
                    }
                }
            } else if (this.normal_activity_id){
                if(!this.editCheckActivityType){
                    console.log('null to normal')
                    let singleAct = this.normalActivityListParsed.find((act) => act.value == this.normal_activity_id); //get single object of selected activity {text & value}
                    let normal_normal_activity_obj = {
                        "crf69_Activities@odata.bind": `/crf69_activitieses(${singleAct.value})`
                    }
                    try{
                        await $.patch("/api/session", {attendance_id,attendance_obj});
                        await $.patch("/api/session", {attendance_id,normal_normal_activity_obj});
                        this.lottieRedirect()
                    }catch{
                        this.errorRedirect()
                    }
                }else if(this.bothNormalActList.map(item => item.crf69_activitiesid).includes(this.editCheckActivityType)){
                    console.log('normal to normal')
                    let singleAct = this.normalActivityListParsed.find((act) => act.value == this.normal_activity_id); //get single object of selected activity {text & value}
                    let normal_normal_activity_obj = {
                        "crf69_Activities@odata.bind": `/crf69_activitieses(${singleAct.value})`
                    }
                    try{
                        await $.patch("/api/session", {attendance_id,attendance_obj});
                        console.log(attendance_id, attendance_obj)
                        await $.patch("/api/session", {attendance_id,normal_normal_activity_obj});
                        this.lottieRedirect()
                    }catch{
                        this.errorRedirect()
                    }
                }else if(!this.bothNormalActList.map(item => item.crf69_activitiesid).includes(this.editCheckActivityType)){
                    console.log("recur to normal")
                    let singleAct = this.normalActivityListParsed.find((act) => act.value == this.normal_activity_id); //get single object of selected activity {text & value}
                    let recur_normal_activity_id = this.editAttendance.crf69_Activities.crf69_activitiesid //get the id of activity you want to edit
                    let recur_normal_activity_obj = {
                        "crf69_Activities@odata.bind": `/crf69_activitieses(${singleAct.value})`
                    }
                    try{
                        await $.patch("/api/session", {attendance_id,attendance_obj});
                        await $.patch("/api/session", {attendance_id,recur_normal_activity_obj});
                        await $.patch("/api/session", {recur_normal_activity_id})
                        this.lottieRedirect()
                    }catch{
                        this.errorRedirect()
                    }
                }
            } else { //if no activity edit, just run normal edit with the attendance
                console.log("Edit Without Activity")
                try{
                    await $.patch("/api/session", {attendance_id,attendance_obj});
                    this.lottieRedirect()
                }catch{
                    this.errorRedirect()
                }
            }
        },
    },
    computed:{
        recurringActivityListParsed(){
            let output = this.activityList.map(item=>{
                return {
                    text: item.crf69_name,
                    value: item.crf69_recurringactivityid,
                    scheduledtime: item.crf69_scheduledtime,
                    duration: item.crf69_duration,
                    org: item._crf69_organisationassociation_value,
                    pro: item._crf69_programme_value,
                    scheme: item._crf69_scheme_value
                }
            }).filter(item=>!!item.value);
            return [
                {
                    text: "Please select an activity",
                    value:null,
                    disabled:true
                },
                ...output]
        },
        normalActivityListParsed(){
            let output = this.bothNormalActList.map(item=>{
                return {
                    text: item.crf69_activityid,
                    value: item.crf69_activitiesid
                }
            }).filter(item=>!!item.value);
            return [
                {
                    text: "Please select an activity",
                    value:null,
                    disabled:true
                },
                ...output]
        }
    }
}
</script>
<style lang="scss" scoped>

label:after {
    content:" *";
    color: red;
}
.subsect{
    font-family:sans-serif;
    font-size:16px;
    font-weight: 300;
}
.spacing{
    margin-bottom:10px;
}
.s-size{
    font-family: sans-serif;
    font-size:10px;
    font-weight: 200;
}

#timeinput{
    border-radius: 5px;
    padding:4px;
}

@import 'vue-multiselect/dist/vue-multiselect.min.css'
</style> 

