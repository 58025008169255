import Vue from 'vue';
import App from './App.vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import VueRouter from 'vue-router'
import routes from './routes/routes.js';
import './style/app.scss';
import VueNumericInput from 'vue-numeric-input';
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
import axios from 'axios';
import './registerServiceWorker'
Vue.use(VueNumericInput)


Vue.use(VueRouter)

Vue.use(LottieVuePlayer);

const router = new VueRouter({
    mode: 'history',
    routes // short for `routes: routes`
})

Vue.component('v-select', vSelect);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

const vm = new Vue({
    router,
    render: h => h(App),
    data(){
        return{
            ready:false,
            token:localStorage.getItem("token"),
            user:{},
            installEvent: false
        }
    },
    mounted(){
        this.handleStartup();
    },
    methods:{
        goto(path){
            if(this.$route.path !="/"+path){
                this.$router.replace("/"+path);
            }
        },
        goaway(path1, path2){
            if(this.$route.path =="/"+path1){
                this.$router.replace("/"+path2);
            }
        },
        create_client(){
            return axios.create({
                headers:{
                    Authorization:`Bearer ${this.token}`,
                    "x-cty-authorization":this.token
                }
            })
        },
        async handleStartup(){
            let {c,n,o,logout} = this.$route.query;
            let loginAttempt = c && n && o;
            if(logout){
                localStorage.removeItem("token");
                this.token =  null;
                this.goto("landing");
                this.ready = true;
                return;
            }
            if(!this.token && !loginAttempt){
                this.goto("landing");
                this.ready = true;
                return;
            }
            if(loginAttempt){
                try{
                    let params =  new URLSearchParams({c,n,o});
                    let {data} = await axios.get(`/api/login?${params.toString()}`);
                    this.token = data;
                    localStorage.setItem("token",data);
                }catch(err){
                    this.goto("landing");
                    this.ready = true;
                    return;
                }
            }
            try {
                let {data} = await axios.get(`/api/login?check=${this.token}`);
                if(!data.login){
                    this.goto("landing");
                    this.ready = true;
                    return;
                }
                this.user = data.login;
                if(loginAttempt){
                    localStorage.setItem("lastuser",c);
                    localStorage.setItem("lastmobile",n);
                }
                this.goaway("landing", "profile");
            } catch (error){
                console.log(error);
                this.goto("landing")
            }
            this.goaway("", "profile");
    
            this.ready = true;
        },
    }
}).$mount('#app');

window.addEventListener("beforeinstallprompt", (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    vm.installEvent = e;
    
  });
  
