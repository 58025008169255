<template lang="pug">
div
    b-container.container-lg
      b-card#calendar
        Sidebar(name="Calendar")
        div#cal
          b-calendar(block v-model="cal" :date-info-fn="highlightActivityDate" @selected="$bvModal.show('actCalModal')" @context="onContext")
            div.d-flex.justify-content-between
              b-button.ml-3(size="md" variant="outline-danger"  @click="refreshPage") 
                | Refresh
                b-icon.my-auto.ml-1(icon="arrow-clockwise")
              b-button.ml-auto.mr-3(size="md" variant="outline-primary" @click="setToday") Set Today
        br
        div#button-grp
          b-button.p-2.my-2.f-len(variant="secondary" @click="$router.push('/sessionList')")
            b-icon.mr-2(icon="clock-history")
            | View All Past Sessions
          b-button.p-2.my-2.f-len(variant="danger" @click="$bvModal.show('allGeneralEventModal')")
            b-icon.mr-2(icon="clock-history")
            | View All General Events
          b-button.p-2.my-2.f-len#btm(variant="outline-secondary" @click='goToSessionForm')
            b-icon.mr-2(icon="pencil-square")
            | Log a Session

        //Calendar Modal
        b-modal#actCalModal(scrollable centered :title="cal")

          b-button.my-2(v-b-toggle.general-collapse variant="primary" block)
            | General
            b-icon.ml-1(icon="caret-down-fill")
          b-collapse(id="general-collapse" :visible="showGenActivityOnDate.length>0 ? true : false"  class="mt-2")
            b-list-group 
              h5.mt-3(v-if="!showGenActivityOnDate.length") No General Activity on {{ cal }}
              b-list-group-item.my-3.py-2.rounded(button @click="showSessionModal(item.crf69_eventcalendarid)" variant="primary" v-for="item in showGenActivityOnDate" :key="item.crf69_eventcalendarid")
                .d-flex.w-100.justify-content-between
                  h5.mb-1 {{ item.crf69_eventname }}
                b-row
                  b-col.font-weight-bold(cols="3") Date:
                  b-col.font-weight-bold {{ formatDateOnly(item.crf69_startdate) }}

                b-modal(:id="item.crf69_eventcalendarid" centered title="General Detail")
                  b-row.mb-2
                    b-col.font-weight-bold(cols="4") Activity:
                    b-col.font-weight-bold  {{ item.crf69_eventname }}
                  hr
                  b-row.mb-2(v-if="item.crf69_description")
                    b-col.font-weight-bold(cols="4") Description:
                    b-col.font-weight-bold  {{ item.crf69_description }}
                  hr
                  b-row.mb-2
                    b-col.font-weight-bold(cols="4") Date:
                    b-col.font-weight-bold {{ formatDateOnly(item.crf69_startdate) }}
                  template(#modal-footer)
                    b-button.px-3(variant="danger" size="md" class="float-right" @click="$bvModal.hide(item.crf69_eventcalendarid)") Close

          b-button.my-2(v-b-toggle.activity-collapse variant="primary" block)
            | Activity (Programme)
            b-icon.ml-1(icon="caret-down-fill")
          b-collapse(id="activity-collapse" :visible="showOrgActivityOnDate.length>0 ? true : false" class="mt-2")
            b-list-group 
              h5.mt-3(v-if="!showOrgActivityOnDate.length") No Activity on {{ cal }}
              b-list-group-item.my-3.py-2.rounded(button @click="showSessionModal(item.crf69_activitiesid)" variant="primary" v-for="item in showOrgActivityOnDate" :key="item.crf69_activitiesid")
                .d-flex.w-100.justify-content-between
                  h5.mb-1(v-if="item.crf69_activityid") {{ item.crf69_activityid }} 
                  h5.mb-1(v-if="!item.crf69_activityid") Volunteering Activity 
                b-row
                  b-col.font-weight-bold(cols="3") Date:
                  b-col.font-weight-bold {{ formatDateOnly(item.crf69_scheduledon) }}
                b-row
                  b-col.font-weight-bold(cols="3") Time:
                  b-col.font-weight-bold {{ formatTimeOnly(item.crf69_scheduledon) }}

                b-modal(:id="item.crf69_activitiesid" centered title="Activity Detail")
                  b-row.mb-2
                    b-col.font-weight-bold(cols="4") Activity:
                    b-col.font-weight-bold(v-if="item.crf69_activityid")  {{ item.crf69_activityid }}
                    b-col.font-weight-bold(v-if="!item.crf69_activityid")  Volunteering Activity
                  hr
                  b-row.mb-2(v-if="item.crf69_description")
                    b-col.font-weight-bold(cols="4") Description:
                    b-col.font-weight-bold  {{ item.crf69_description }}
                    hr
                  b-row.mb-2
                    b-col.font-weight-bold(cols="4") Date:
                    b-col.font-weight-bold {{ formatDateOnly(item.crf69_scheduledon) }}
                  hr
                  b-row.mb-2
                    b-col.font-weight-bold(cols="4") Time:
                    b-col.font-weight-bold {{ formatTimeOnly(item.crf69_scheduledon) }}
                  template(#modal-footer)
                    b-button.px-3(variant="danger" size="md" class="float-right" @click="$bvModal.hide(item.crf69_activitiesid)") Close

          //Past Session Modal from Calendar
          b-button.my-2(v-b-toggle.past-session-collapse variant="primary" block)
            | Past Sessions 
            b-icon.ml-1(icon="caret-down-fill")
          b-collapse(id="past-session-collapse" :visible="showPastSessionOnDate.length>0 ? true : false" class="mt-2")
            b-list-group 
              h5.mt-3(v-if="!showPastSessionOnDate.length") No Past Session on {{ cal }}
              b-list-group-item.my-3.py-3.rounded(button @click="viewSessionDetail(item.crf69_attendanceid)" variant="primary" v-for="item in showPastSessionOnDate" :key="item.crf69_attendanceid")
                .d-flex.w-100.justify-content-between
                  h5.mb-1(v-if="item.crf69_Activities") {{ item.crf69_Activities.crf69_activityid }}
                  h5.mb-1(v-if="!item.crf69_Activities") Volunteering Activity
                b-row
                  b-col.font-weight-bold(cols="4") Start:
                  b-col.font-weight-bold {{ formatDate(item.crf69_datetime) }}
                b-row
                  b-col.font-weight-bold(cols="4") End:
                  b-col.font-weight-bold {{ formatDate(item.crf69_attendanceend) }}

                // Session Detail Modal
                b-modal(:id="item.crf69_attendanceid" scrollable centered title="Session Detail")
                  b-row.mb-2
                    b-col(cols="5" class="text-right")
                      b Volunteer Role:
                    b-col {{item["crf69_membertype@OData.Community.Display.V1.FormattedValue"]}}
                  b-row.mb-2(v-if="item.crf69_meetingplace")
                    b-col(cols="5" class="text-right")
                      b Meeting Place:
                    b-col {{ item["crf69_meetingplace@OData.Community.Display.V1.FormattedValue"] }}

                  b-row.mb-2
                    b-col(cols="5" class="text-right")
                      b Activity:
                    b-col(v-if="item.crf69_Activities") {{ item.crf69_Activities.crf69_activityid }}
                    b-col(v-if="!item.crf69_Activities") Volunteering Activity

                  b-row.mb-2
                    b-col(cols="5" class="text-right")
                      b Start Time:
                    b-col {{ formatDate(item.crf69_datetime) }}

                  b-row.mb-2
                    b-col(cols="5" class="text-right")
                      b End Time:
                    b-col {{ formatDate(item.crf69_attendanceend) }}

                  b-row.mb-2(v-if="item.crf69_topicsissuesdiscussed")
                    b-col(cols="5" class="text-right")
                      b Topic:
                    b-col(cols="7") {{ item["crf69_topicsissuesdiscussed@OData.Community.Display.V1.FormattedValue"] }}

                  b-row.mb-2(v-if="item.crf69_topicnotes")
                    b-col(cols="5" class="text-right")
                      b Topic Notes:
                    b-col(cols="7") {{ item.crf69_topicnotes }}

                  b-row.mb-2(v-if="item.crf69_tempermentofmentee")
                    b-col(cols="5" class="text-right")
                      b Temperament:
                    b-col(cols="7") {{ item["crf69_tempermentofmentee@OData.Community.Display.V1.FormattedValue"] }}

                  b-row.mb-2(v-if="item.crf69_temperamentnotes")
                    b-col(cols="5" class="text-right")
                      b Temperament Notes:
                    b-col(cols="7") {{ item.crf69_temperamentnotes }}

                  b-row.mb-2(v-if="item.crf69_menteefamilysituation")
                    b-col(cols="5" class="text-right")
                      b Family:
                    b-col(cols="7") {{ item["crf69_menteefamilysituation@OData.Community.Display.V1.FormattedValue"] }}

                  b-row.mb-2(v-if="item.crf69_familynotes")
                    b-col(cols="5" class="text-right")
                      b Family Notes:
                    b-col(cols="7") {{ item.crf69_familynotes }}
                  
                  b-row.mb-2(v-if="item.crf69_areasofconcern")
                    b-col(cols="5" class="text-right")
                      b Concern:
                    b-col(cols="7") {{ item.crf69_areasofconcern }}

                  b-row.mb-2(v-if=" item.crf69_comments_caseworker")
                    b-col(cols="5" class="text-right")
                      b Comment:
                    b-col(cols="7") {{ item.crf69_comments_caseworker }}

                  b-row.mb-2(v-if="item.crf69_challengesfaced")
                    b-col(cols="5" class="text-right")
                      b Challenge:
                    b-col(cols="7") {{ item.crf69_challengesfaced }}

                  b-row.mb-2(v-if="item.crf69_remarks")
                    b-col(cols="5" class="text-right")
                      b Remark:
                    b-col(cols="7") {{ item.crf69_remarks }}

                  template(#modal-footer)
                    b-button.px-3(variant="primary" size="md" class="float-right" @click="editSessionDetail(item.crf69_attendanceid)") Edit
                    b-button.px-3(variant="danger" size="md" class="float-right" @click="$bvModal.hide(item.crf69_attendanceid)") Close
          template(#modal-footer)
            b-button(variant="danger" size="md" class="float-right" @click="$bvModal.hide('actCalModal')") Close

        //View ALL Past Session Modal
        b-modal(scrollable centered v-model="pastSessionModalShow")
          template(#modal-header)
            h4 All Past Sessions
          div.text-center(v-if="ready==false")
            b-spinner(label="Spinning")
          b-list-group(v-if="ready==true")
            h3(v-if="!past_sessions.length") No Past Session Found
            b-list-group-item.my-4.py-3.rounded(button @click="viewSessionDetail(item.crf69_attendanceid)" variant="primary" v-for="item in past_sessions" :key="item.crf69_attendanceid")
              .d-flex.w-100.justify-content-between
                h5.mb-1(v-if="item.crf69_Activities") {{ item.crf69_Activities.crf69_activityid }}
                h5.mb-1(v-if="!item.crf69_Activities") Volunteering Activity
              b-row
                b-col.font-weight-bold(cols="4") Start:
                b-col.font-weight-bold {{ formatDate(item.crf69_datetime) }}
              b-row
                b-col.font-weight-bold(cols="4") End:
                b-col.font-weight-bold {{ formatDate(item.crf69_attendanceend) }}

              //Session Details Modal
              b-modal(:id="item.crf69_attendanceid" centered title="Session Detail")
                b-row.mb-2
                  b-col(cols="5" class="text-right")
                    b Volunteer Role:
                  b-col {{item["crf69_membertype@OData.Community.Display.V1.FormattedValue"]}}
                b-row.mb-2(v-if="item.crf69_meetingplace && item.crf69_meetingplace != '955870003'")
                  b-col(cols="5" class="text-right")
                    b Meeting Place:
                  b-col {{ item["crf69_meetingplace@OData.Community.Display.V1.FormattedValue"] }}

                //- If the place is "Others" show detail
                b-row.mb-2(v-if="item.crf69_meetingplacenotes")
                  b-col(cols="5" class="text-right")
                    b Meeting Place:
                  b-col {{ item.crf69_meetingplacenotes }}

                b-row.mb-2
                  b-col(cols="5" class="text-right")
                    b Activity:
                  b-col(v-if="item.crf69_Activities") {{ item.crf69_Activities.crf69_activityid }}
                  b-col(v-if="!item.crf69_Activities") Volunteering Activity

                b-row.mb-2
                  b-col(cols="5" class="text-right")
                    b Start Time:
                  b-col {{ formatDate(item.crf69_datetime) }}

                b-row.mb-2
                  b-col(cols="5" class="text-right")
                    b End Time:
                  b-col {{ formatDate(item.crf69_attendanceend) }}

                b-row.mb-2(v-if="item.crf69_topicsissuesdiscussed")
                  b-col(cols="5" class="text-right")
                    b Topic:
                  b-col(cols="7") {{ item["crf69_topicsissuesdiscussed@OData.Community.Display.V1.FormattedValue"] }}

                b-row.mb-2(v-if="item.crf69_topicnotes")
                  b-col(cols="5" class="text-right")
                    b Topic Notes:
                  b-col(cols="7") {{ item.crf69_topicnotes }}

                b-row.mb-2(v-if="item.crf69_tempermentofmentee")
                  b-col(cols="5" class="text-right")
                    b Temperament:
                  b-col(cols="7") {{ item["crf69_tempermentofmentee@OData.Community.Display.V1.FormattedValue"] }}

                b-row.mb-2(v-if="item.crf69_temperamentnotes")
                  b-col(cols="5" class="text-right")
                    b Temperament Notes:
                  b-col(cols="7") {{ item.crf69_temperamentnotes }}

                b-row.mb-2(v-if="item.crf69_menteefamilysituation")
                  b-col(cols="5" class="text-right")
                    b Family:
                  b-col(cols="7") {{ item["crf69_menteefamilysituation@OData.Community.Display.V1.FormattedValue"] }}

                b-row.mb-2(v-if="item.crf69_familynotes")
                  b-col(cols="5" class="text-right")
                    b Family Notes:
                  b-col(cols="7") {{ item.crf69_familynotes }}
                
                b-row.mb-2(v-if="item.crf69_areasofconcern")
                  b-col(cols="5" class="text-right")
                    b Concern:
                  b-col(cols="7") {{ item.crf69_areasofconcern }}

                b-row.mb-2(v-if=" item.crf69_comments_caseworker")
                  b-col(cols="5" class="text-right")
                    b Comment:
                  b-col(cols="7") {{ item.crf69_comments_caseworker }}

                b-row.mb-2(v-if="item.crf69_challengesfaced")
                  b-col(cols="5" class="text-right")
                    b Challenge:
                  b-col(cols="7") {{ item.crf69_challengesfaced }}

                b-row.mb-2(v-if="item.crf69_remarks")
                  b-col(cols="5" class="text-right")
                    b Remark:
                  b-col(cols="7") {{ item.crf69_remarks }}

                template(#modal-footer)
                  b-button.px-3(variant="primary" size="md" class="float-right" @click="editSessionDetail(item.crf69_attendanceid)") Edit
                  b-button.px-3(variant="danger" size="md" class="float-right" @click="$bvModal.hide(item.crf69_attendanceid)") Close
          template(#modal-footer)
              b-button(variant="danger" size="md" class="float-right" @click="pastSessionModalShow=!pastSessionModalShow") Close

        //View All General Events
        b-modal#allGeneralEventModal(scrollable centered)
          template(#modal-header)
            h4 All General Event
          b-list-group 
            b-list-group-item.my-3.py-4.rounded(button @click="showSessionModal(item.crf69_eventcalendarid)" variant="primary" v-for="item in gen_activities" :key="item.crf69_eventcalendarid")
              .d-flex.w-100.justify-content-between
                h5.mb-1 {{ item.crf69_eventname }}</h5>
              b-row
                b-col.font-weight-bold(cols="2") Date:
                b-col.font-weight-bold {{ formatDateOnly(item.crf69_startdate) }}

              //Session Details Modal
              b-modal(:id="item.crf69_eventcalendarid" centered title="Session Detail")
                b-row.mb-2
                  b-col(cols="5" class="text-right")
                    b Activity:
                  b-col {{ item.crf69_eventname }}

                b-row.mb-2
                  b-col(cols="5" class="text-right")
                    b Date:
                  b-col {{ formatDateOnly(item.crf69_startdate) }}

                b-row.mb-2
                  b-col(cols="5" class="text-right")
                    b Description:
                  b-col {{ item.crf69_description }}

                template(#modal-footer)
                  b-button.px-3(variant="danger" size="md" class="float-right" @click="$bvModal.hide(item.crf69_eventcalendarid)") Close
          template(#modal-footer)
              b-button(variant="danger" size="md" class="float-right" @click="$bvModal.hide('allGeneralEventModal')") Close

</template>
<script>
//import axios from "axios";
import Sidebar from "./components/sidebar";
import dayjs from 'dayjs';

export default {
  name: "calendarMb",
  components: {
    Sidebar,
  },
  data() {
    return {
      ready:false,
      dayjs,
      gen_activities:[], //general
      org_activities:[], //org
      pro_activities:[], //programme
      past_sessions:[], //past session
      pbtn: false,
      head: { blank: true, width: 75, height: 75, class: "m1" },
      cal: null,
      context:null,
      selected:[],
      pastSessionModalShow:false,
    };
  },
  methods: {
    highlightActivityDate(ymd,date){
      const orgActivity = this.org_activities.map(act=>{
        return this.formatDateOnly(act.crf69_scheduledon)
      })
      const pastSession = this.past_sessions.map(act=>{
        return this.formatDateOnly(act.crf69_datetime)
      })
      const genActivity = this.gen_activities.map(act=>{
        return this.formatDateOnly(act.crf69_startdate)
      })
      let allActivityArray = orgActivity.concat(pastSession) //combine all types of activity into one array to loop through for date highlight
      const year = date.getFullYear()
      const month = date.getMonth()
      const day = date.getDate()
      for(let i = 0; i<genActivity.length; i++){
        let yearact = dayjs(genActivity[i]).format('YYYY')
        let monthact = dayjs(genActivity[i]).format('MM') - 1
        let dayact = dayjs(genActivity[i]).format('DD')
        if(year == yearact && month == monthact && day == dayact){
          return 'table-danger'
        }
      }
      for(let i = 0; i<allActivityArray.length; i++){
        let yearact = dayjs(allActivityArray[i]).format('YYYY')
        let monthact = dayjs(allActivityArray[i]).format('MM') - 1
        let dayact = dayjs(allActivityArray[i]).format('DD')
        if(year == yearact && month == monthact && day == dayact){
          return 'table-primary'
        }
      }
      return ''
    },
    showSessionModal(modalid){
      this.$bvModal.show(modalid)
    },
    viewSessionDetail(id){
      this.$router.push(`/view/${id}`);
    },
    formatDate(date) {
      return dayjs(date).format('DD/MM/YYYY hh:mm A')
    },
    formatDateOnly(date){
      return dayjs(date).format('YYYY-MM-DD')
    },
    formatTimeOnly(date) {
      return dayjs(date).format('hh:mm A')
    },
    setToday() {
      const now = new Date();
      this.cal = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      console.log(this.cal)
      console.log("past :" , this.past_sessions)
      console.log("org :" ,this.org_activities)
      console.log("pro :" ,this.pro_activities)
    },
    refreshPage() {
      location.href="/calendar"
    },
    goToSessionForm() {
      this.$router.push(this.calLink);
    },
    editSessionDetail(modalid) {
      this.$router.push(`/sessionForm/edit/${modalid}`);
    },
    onContext(ctx) {
      this.cal = ctx.activeYMD
    },
  },
  computed: {
    calLink() {
      return `/sessionForm/create/${this.cal}`;
    },
    showOrgActivityOnDate(){
      return this.org_activities.filter(obj=>this.formatDateOnly(obj.crf69_scheduledon) === this.cal)
    },
    showPastSessionOnDate(){
      return this.past_sessions.filter(obj=>this.formatDateOnly(obj.crf69_datetime) === this.cal)
    },
    showProActivityOnDate(){
      return this.pro_activities.filter(obj=>this.formatDateOnly(obj.crf69_scheduledon) === this.cal)
    },
    showGenActivityOnDate(){
      return this.gen_activities.filter(obj=>this.formatDateOnly(obj.crf69_startdate) === this.cal)
    }

  },
  async mounted(){
    let $ = this.$root.create_client();
    let arr = [
        $.get("/api/session").then(({data})=>{ //for activity date highlight
            this.org_activities = data.orgactivity;
            this.pro_activities = data.proactivity;
            this.gen_activities = data.genactivity;
            return data;
        }),
        $.get("/api/past_sessions").then(({data})=>{ //for past sessions (date highlight and view)
            this.past_sessions = data;
            return data;
        }),
      ];
    
    Promise.all(arr).then(()=>{
      this.ready = true;
      // sort the past sessions by date
      this.past_sessions = this.past_sessions.sort(
        (objA, objB) => Number(new Date(objB.crf69_datetime)) - Number(new Date(objA.crf69_datetime)),
      );
      this.gen_activities = this.gen_activities.sort(
        (objA, objB) => Number(new Date(objB.crf69_startdate)) - Number(new Date(objA.crf69_startdate)),
      );
    }).catch(()=>{
        alert("Unable to connect to the system. Please try your submission another time.");
    })
    
  },
}


</script>
<style scoped>
.field.form-control {
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #1d3557;
}
#button-grp{
  margin-top:5%;
}
.f-len{
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}
#btm{
  margin-top: 5px ;
}
</style>