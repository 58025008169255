<template lang="pug">
div
    b-container.container-lg
        b-card#calendar
            Sidebar(name="Past Sessions")
            div.d-flex.justify-content-center.align-items-center.h-75(v-if="!ready")
                b-spinner(label="Spinning")
            div.h-100(v-if="ready")
                div.m-2.p-2.bg-lightinfo.shadow.rounded
                    .d-flex.justify-content-between
                        b-button(@click="$router.go(-1)")
                            b-icon.mr-1(icon="arrow-left")
                            | Back
                        router-link(to="/sessionForm" tag="b-button")
                            b-icon.mr-1(icon="file-earmark-plus")
                            | Log Session
                        b-button(@click="filterCollapse = !filterCollapse")
                            b-icon.mr-1(icon="filter")
                            | Filter
                    b-collapse(v-model="filterCollapse")
                        div.border.rounded.bg-white.my-1.p-2
                            b-input(type="month" @change="filterData" v-model="selectedFilterMonth")
                div.m-2.p-2.bg-lightinfo.shadow.rounded.d-flex.flex-column.justify-content-center
                    b-table.rounded.bg-white.shadow(:items="filteredData" :fields="tableFields" :per-page="perPage" :current-page="currentPage" hover bordered responsive head-variant="light")
                        template(#cell(activity)="data")
                            p(v-if="!data.item.crf69_Activities") Volunteering Activity
                            p(v-else-if="!data.item.crf69_Activities._crf69_parentrecurringactivity_value") {{ data.item.crf69_Activities.crf69_activityid}}
                            p(v-else-if="data.item.crf69_Activities._crf69_parentrecurringactivity_value") {{ data.item.crf69_Activities["_crf69_parentrecurringactivity_value@OData.Community.Display.V1.FormattedValue"]}}
                            b-badge(variant="success" v-if=" !data.item.crf69_Activities || !data.item.crf69_Activities._crf69_parentrecurringactivity_value") One Off Activity
                            b-badge(variant="success" v-else-if="data.item.crf69_Activities._crf69_parentrecurringactivity_value") Recurring Activity
                        template(#cell(time)="data")
                            p {{ formatDate(data.item.crf69_datetime) }}
                        template(#cell(action)="data")
                            b-button.m-1(@click="viewSessionDetail(data.item.crf69_attendanceid)")
                                b-icon(icon="eye")
                            b-button.m-1(@click="editSessionDetail(data.item.crf69_attendanceid)")
                                b-icon(icon="pencil")
                    h6.font-weight-bold.mx-auto.my-4(v-if="filteredData.length == 0 && selectedFilterMonth") No past sessions found on {{ selectedFilterMonth }}
                    h6.font-weight-bold.mx-auto.my-4(v-else-if="filteredData.length == 0") No past sessions found
                    b-pagination.mx-auto(
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                    )

</template>
<script>
//import axios from "axios";
import Sidebar from "./components/sidebar";
import dayjs from 'dayjs';

export default {
    name: "calendarMb",
    components: {
    Sidebar,
},
    data() {
        return {
            filterCollapse:false,
            ready:false,
            dayjs,
            past_sessions:[],
            filteredData:[],
            profileData:[],
            pbtn: false,
            head: { blank: true, width: 75, height: 75, class: "m1" },
            cal: null,
            context:null,
            selected:[],
            perPage: 10,
            selectedFilterMonth:null,
            currentPage: 1,
            totalRows:0,
            tableFields:[
                {
                    key: 'activity',
                    sortable: false,
                    label:"Activity"
                },
                {
                    key: 'time',
                    sortable: false,
                    label:"Date"
                },
                {
                    key: 'action',
                    sortable: false,
                    label:"Action"
                },
            ]
        };
    },
    methods: {
        viewSessionDetail(id){
            this.$router.push(`/view/${id}`);
        },
        editSessionDetail(id) {
            this.$router.push(`/sessionForm/edit/${id}`);
        },
        formatDate(date) {
            return dayjs(date).format('DD/MM/YYYY hh:mm A')
        },
        formatDateOnly(date){
            return dayjs(date).format('YYYY-MM-DD')
        },
        formatTimeOnly(date) {
            return dayjs(date).format('hh:mm A')
        },
        filterData() {
            if(!this.selectedFilterMonth){
                this.filteredData = this.past_sessions
            }else{
                this.filteredData = this.past_sessions.filter((item) => {
                    const itemDate = dayjs(item.crf69_datetime);
                    const selectedMonth = dayjs(this.selectedFilterMonth);
                    return (
                    selectedMonth.month() === itemDate.month() &&
                    selectedMonth.year() === itemDate.year()
                    );
                });
            }
        },
    },
    computed: {
        
    },
    async mounted(){
        let $ = this.$root.create_client();
        let arr = [
            $.get("/api/profile").then(({data})=>{//bad naming convention probably.
                this.profileData = data[0];
                return data;
            }), 
            $.get("/api/past_sessions").then(({data})=>{ //for past sessions (date highlight and view)
                this.past_sessions = data;
                return data;
            }),
        ]
        Promise.all(arr).then(()=>{
            this.ready = true;
            // console.log(this.profileData);
            // console.log(this.past_sessions);
            this.filteredData = this.past_sessions.sort(
                (objA, objB) => Number(new Date(objB.crf69_datetime)) - Number(new Date(objA.crf69_datetime)),
            );
            console.log(this.filteredData);
            this.totalRows=this.past_sessions.length
        }).catch(()=>{
            alert("There's seem to be an issue");
        })
        
    },
}


</script>
<style scoped>
.field.form-control {
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #1d3557;
}
#button-grp{
    margin-top:5%;    
}
.f-len{
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}
#btm{
    margin-top: 5px ;
}
</style>