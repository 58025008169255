export default {
    methods:{
        reducer(opt){
            return String(opt?.code||"");
        },
        validateArray(val){
            if(Array.isArray(val)){
                return val.length ===0;
            }
            return val === "" || val === null;
        },

    },
    computed:{
        finalData() {
            const formData = JSON.parse(JSON.stringify(this.formdata));
            for(let key in formData){
                if(formData[key] ==="" || formData[key]===null || (Array.isArray(formData[key]) && formData[key].length < 1)){
                    delete formData[key];
                    continue;
                }

                formData[key] = Array.isArray(formData[key]) ? 
                formData[key].map(a=>this.reducer(a)).join(',')
                : 
                typeof formData[key] == "object" && formData[key] !== null  && ('code' in formData[key]) ?
                this.reducer(formData[key])
                : String(formData[key]);
            }
            return formData;
        }
    }
}